import { Link } from "react-router-dom"
import { FaBeer, HiMenu, HiUser, HiUserCircle, HiUserGroup } from "react-icons/hi";
import { useState } from "react";

export const Header = () => {
    const [language, setLanguage] = useState("")
    return (
        <div className="header">
            <div className="inner">
                <Link to="/">
                    <img className="logo" src="logo.png" />
                </Link>
                <div className="menu">
                    <div className="list">
                        <Link to="/tours" className="item">Tours</Link>
                        <Link to="/events" className="item">Events</Link>
                        <Link to="/hotels" className="item">Hotels</Link>
                    </div>
                    <div className="currency">
                        <div className="list">
                            <span className="item">USD</span>
                            <span className="item">TRY</span>
                        </div>
                    </div>
                    <span>asd</span>
                    <Link to={localStorage.getItem("accountId") != null ? "/account" : "/auth"} className="account">
                        <HiUserCircle />
                        <span>Account</span>
                    </Link>
                    <HiMenu className="mobile" />
                </div>
            </div>
        </div>
    )
}