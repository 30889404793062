import { Link } from "react-router-dom"

export const Footer = () => {
    return (
        <div className="footer pad">
            <img className="logo" src="logo.png" />
            <a href="tel:+905011014731">Call: +90 545 911 17 81</a>
            <div className="inner">
                <div className="list">
                    <span className="headline">Services</span>
                    <div className="items">
                        <Link to="/transfers">Transfers</Link>
                        <Link to="/tours">Tours</Link>
                        <Link to="/trips">Trips</Link>
                        <Link to="/hotels">Hotels</Link>
                        <Link to="/events">Events</Link>
                    </div>
                </div>
                <div className="list">
                    <span className="headline">Reservation</span>
                    <div className="items">
                        <Link to="/transfers">Check-In</Link>
                        <Link to="/tours">List Your Transfer</Link>
                    </div>
                </div>
                <div className="list">
                    <span className="headline">Company</span>
                    <div className="items">
                        <Link to="/about">About Us</Link>
                        <Link to="/privacy">Privacy Policy</Link>
                        <Link to="/terms">Terms of Service</Link>
                        <Link to="/contact">Contact Us</Link>
                    </div>
                </div>
                <div className="list">
                    <span className="headline">Get App</span>
                    <div className="items">
                        <a href="https://play.google.com/store/apps/details?id=gotransfer.app">Google Play</a>
                        <a href="">App Store</a>
                    </div>
                </div>
            </div>
        </div>
    )
}