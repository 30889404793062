import { useState } from "react"
import { apiUrl } from "../../Utils/Consts"
import { useNavigate } from "react-router-dom"

export const AgencyCreate = () => {
    const [agencyName, setAgencyName] = useState();
    var navigate = useNavigate();
    const agencyCreate = () => {
        const requestBody = {
            "agencyName": agencyName,
            "accountId": localStorage.getItem("accountId")
        }
        const requestMetadata = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        }
        fetch(`${apiUrl}/agency/create`, requestMetadata)
            .then(res => res.json())
            .then(
                (res) => {
                    if (res.responseStatus) {
                        navigate("/account")
                    }
                },
                (error) => {
                }
            )
    }
    return (
        <div className="sui-page pad">
            {/*             <Group
                title="Create Agency"
                items=
                {
                    [
                        < GroupItem
                            title="Agency Name"
                            trailing=
                            {
                                < InputField
                                    type="text"
                                    onChange={e => setAgencyName(e.target.value)}
                                    style={{ width: "100%" }}
                                    value={agencyName}
                                />
                            }
                            trailingStyle={{ width: "75%" }}
                        />
                    ]
                }
            />
            <Group
                items=
                {
                    [
                        < GroupItem
                            title="Create"
                            titleStyle={{ color: "var(--link)" }}
                            onClick={() => agencyCreate()}
                            trailingStyle={{ width: "75%" }}
                        />
                    ]
                }
            /> */}
        </div>
    )
}