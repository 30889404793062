import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "../Pages/Home"
import { NotFound } from "../Pages/NotFound";
import { Tours } from "../Pages/Tours";
import { Hotels } from "../Pages/Hotels";
import { Auth } from "../Pages/Auth";
import { AgencyHome } from "../Pages/Agent/Home";
import { Header } from "../Utils/Header";
import { Footer } from "../Utils/Footer";
import { Tour } from "../Pages/Tour";
import { Events } from "../Pages/Events";
import { Account } from "../Pages/Account/Account";
import { AgencyCreate } from "../Pages/Agent/Create";
import { AccountDetails } from "../Pages/Account/Details";

export const PageRoutes = () => {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/transfers" element={<Tours />} />
                <Route path="/transfers/new" element={<Tours />} />
                <Route path="/tours" element={<Tours />} />
                <Route path="/tours/:tourId" element={<Tour />} />
                <Route path="/hotels" element={<Hotels />} />
                <Route path="/events" element={<Events />} />
                <Route path="/account" element={<Account />} />
                <Route path="/account/details" element={<AccountDetails />} />
                <Route path="/auth" element={<Auth />} />
                <Route path="/agency" element={<AgencyHome />} />
                <Route path="/agency/create" element={<AgencyCreate />} />
                <Route path="/agency/reservation/create" element={<AgencyCreate />} />
                <Route path="/agency/reservation/list" element={<AgencyCreate />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
            <a href="https://api.whatsapp.com/send?phone=905459111781" target="_blank" className="wp">
                <img src="https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-whatsapp-mobile-software-icon-png-image_6315991.png" width={54} height={54} />
            </a>
        </BrowserRouter>
    )
}