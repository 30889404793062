import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { apiUrl } from "../../Utils/Consts"
import { HiChevronRight, HiOutlineChevronRight } from "react-icons/hi"
export const Account = () => {
    var navigate = useNavigate()
    const [accountData, setAccountData] = useState();
    const getAccountData = () => {
        fetch(`${apiUrl}/account/data?accountId=${localStorage.getItem("accountId")}`)
            .then(res => res.json())
            .then(
                (res) => {
                    setAccountData(res.accountData);
                },
                (error) => {
                }
            )
    }
    const logOut = () => {
        localStorage.removeItem("accountId")
        localStorage.removeItem("accountPhoneNumber")
        navigate("/")
    }
    useEffect(() => {
        if (localStorage.getItem("accountId") == null) {
            navigate("/auth")
        }
        else {
            getAccountData()
        }
    }, [])
    return (
        <div className="sui-page pad page-account fade-in">
            {/*             {accountData ?
                <div>
                    <Group
                        title="Account"
                        items=
                        {
                            [
                                <GroupItem
                                    title="Reservations"
                                    onClick={() => navigate("/reservations")}
                                    trailing=
                                    {
                                        <HiOutlineChevronRight opacity={0.5} fontSize={18} />
                                    }
                                />,
                                <GroupItem
                                    title="Account Details"
                                    onClick={() => navigate("/account/details")}
                                    trailing=
                                    {
                                        <HiOutlineChevronRight opacity={0.5} fontSize={18} />
                                    }
                                />
                            ]
                        }
                    />
                    <Group
                        items=
                        {
                            [
                                <GroupItem
                                    title="Log out"
                                    titleStyle={{ color: "red" }}
                                    onClick={() => logOut()}
                                />
                            ]
                        }
                    />
                    {accountData.agencyData ?
                        <>
                            <Group
                                title={accountData.agencyData.agencyName}
                                items=
                                {
                                    [
                                        <GroupItem
                                            title="Create Reservation"
                                            onClick={() => navigate("/agency/transfer/list")}
                                        />,
                                        <GroupItem
                                            title="All Reservations"
                                            onClick={() => navigate("/agency/transfer/list")}
                                        />
                                    ]
                                }
                            />
                            <Group
                                items=
                                {
                                    [
                                        <GroupItem
                                            title="My Transfers"
                                            onClick={() => navigate("/agency/transfer/list")}
                                        />,
                                        <GroupItem
                                            title="Create Transfer"
                                            onClick={() => navigate("/agency/transfer/create")}
                                        />,
                                    ]
                                }
                            />
                        </>
                        :
                        <Group
                            title={"List Your Business"}
                            items=
                            {
                                [
                                    <GroupItem title="Create Agency" onClick={() => navigate("/agency/create")} />,
                                ]
                            }
                        />
                    }
                </div>
                :
                <Spinner />
            } */}
        </div>
    )
}