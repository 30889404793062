import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Home = () => {
    const { t } = useTranslation();

    return (
        <div className="home page">
            <div className="booking pad">
                <div className="inner">
                    <span className="title">Find Transfer</span>
                    <span className="alt">Get your transfer ready within 3 minutes.</span>
                    <div className="search">
                        <div className="input">
                            <div className="label">
                                <img className="icon" src="location.png" />
                                <span>Location</span>
                            </div>
                            <input type="text" placeholder="" />
                        </div>
                        <div className="input">
                            <div className="label">
                                <img className="icon" src="destination.png" />
                                <span>Destination</span>
                            </div>
                            <input type="text" placeholder="" />
                        </div>
                        <div className="increment">
                            <div className="label">
                                <img className="icon" src="passengers.png" />
                                <span>Passengers</span>
                            </div>
                            <div className="value">
                                <img className="icon" src="remove-circle-outline.png" />
                                <span>1 Person</span>
                                <img className="icon" src="add-circle-outline.png" />
                            </div>
                            <div className="value">
                                <img className="icon" src="remove-circle-outline.png" />
                                <span>2 Childs</span>
                                <img className="icon" src="add-circle-outline.png" />
                            </div>
                        </div>
                        <div className="increment">
                            <div className="label">
                                <img className="icon" src="baggages.png" />
                                <span>Baggages</span>
                            </div>
                            <div className="value">
                                <img className="icon" src="remove-circle-outline.png" />
                                <span>2</span>
                                <img className="icon" src="add-circle-outline.png" />
                            </div>
                        </div>
                        <div className="find">
                            <img className="icon" src="search.png" />
                            <span>Search</span>
                        </div>
                    </div>
                </div>
            </div>
            Call +90 5459111781
            <div className="features pad">
                <div className="list">
                    <div className="item">
                        <img className="icon" src="price.png" />
                        <div className="details">
                            <span className="title">Fair Price</span>
                            <span className="alt">We compare and get best
                                offer for you.</span>
                        </div>
                    </div>
                    <div className="item">
                        <img className="icon" src="support.png" />
                        <div className="details">
                            <span className="title">Live Support</span>
                            <span className="alt">7/24 Live, email and phonesupport.</span>
                        </div>
                    </div>
                    <div className="item">
                        <img className="icon" src="ticket.png" />
                        <div className="details">
                            <span className="title">Anytime Cancel</span>
                            <span className="alt">You can cancel your reservations 12 hours in advance for free.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tours pad">
                <span className="title">Trending Tours</span>
                <div className="list">
                    <Link to="/tour/alanya" className="item" style={{ backgroundImage: "url(alanya.jpg)" }}>
                        <div className="shadow"></div>
                        <span className="title">Alanya</span>
                    </Link>
                    <div className="item" style={{ backgroundImage: "url(airport.jpeg)" }}>
                        <div className="shadow"></div>
                        <span className="title">Antalya Airport</span>
                    </div>
                    <div className="item" style={{ backgroundImage: "url(kundu.png)" }}>
                        <div className="shadow"></div>
                        <span className="title">Kundu</span>
                    </div>
                    <div className="item" style={{ backgroundImage: "url(manavgat.jpg)" }}>
                        <div className="shadow"></div>
                        <span className="title">Manavgat</span>
                    </div>
                    <div className="item" style={{ backgroundImage: "url(belek.jpeg)" }}>
                        <div className="shadow"></div>
                        <span className="title">Belek</span>
                    </div>
                    <div className="item" style={{ backgroundImage: "url(kas.jpeg)" }}>
                        <div className="shadow"></div>
                        <span className="title">Kaş</span>
                    </div>
                    <div className="item" style={{ backgroundImage: "url(airport.jpeg)" }}>
                        <div className="shadow"></div>
                        <span className="title">Alanya</span>
                    </div>
                </div>
            </div>
            <div className="transfers pad">
                <span className="title">Popular Transfers</span>
                <div className="list">
                    <div className="item">
                        <div className="gallery">
                            <img className="big" src="https://antalyahavaalanitransferfiyatlari.com/wp-content/uploads/2021/05/antalya-vip-transfer-1.jpg" />
                            <div className="col">
                                <img className="small" src="https://antalyahavaalanitransferfiyatlari.com/wp-content/uploads/2021/05/antalya-vip-transfer.jpg" />
                                <img className="small" src="https://www.novaviptransfer.com/wp-content/uploads/2022/07/antalya-transfer-1.jpg" />
                            </div>
                            <div className="col">
                                <img className="small" src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/27/79/1a/c9/direct-vip-transfer.jpg?w=1200&h=900&s=1" />
                                <img className="small" src="https://antalyahavaalanitransferfiyatlari.com/wp-content/uploads/2021/05/antalya-vip-transfer.jpg" />
                            </div>
                        </div>
                        <div className="details">
                            <span className="title">John Doe VIP Transfer</span>
                            <span className="tiny">Mercedes Vito</span>
                            <span className="tiny">ETA 25m</span>
                            <div className="rating">

                            </div>
                            <span className="discount">25$</span>
                            <span className="price">15$</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}