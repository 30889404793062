import { useEffect, useState } from "react";
import { apiUrl } from "../../Utils/Consts";

export const AgencyHome = () => {
    const [homeData, setHomeData] = useState();
    const getAgencyHomeData = () => {
        const requestBody = {
            accountId: localStorage.getItem("accountId")
        }
        const requestMetadata = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        }
        fetch(`${apiUrl}/agencyData`, requestMetadata)
            .then(res => res.json())
            .then(
                (res) => {
                    setHomeData(res);
                },
                (error) => {
                }
            )
    }
    useEffect(() => {
        getAgencyHomeData()
    }, [])
    return (
        <div className="sui-page pad fade-in">
            {homeData ?

                <div className="transfer-create">
                </div>
                :
                <span>Loading</span>
            }
        </div>
    )
}