import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { useEffect, useState } from "react";
import { apiUrl, countries } from "../Utils/Consts";
import { useNavigate } from "react-router-dom";
import { HiChevronRight, HiOutlineChevronRight, HiUserCircle } from "react-icons/hi";
export const Auth = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyA87rgQREiqjozO6jCXZ3W_bPaYrrqN0XE",
        authDomain: "gotransfer-3c3b5.firebaseapp.com",
        projectId: "gotransfer-3c3b5",
        storageBucket: "gotransfer-3c3b5.appspot.com",
        messagingSenderId: "154213324277",
        appId: "1:154213324277:web:b1c702bf5e07d20e6dfcbc",
        measurementId: "G-XLRFJL93G0"
    };

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [verificationStatus, setVerificationStatus] = useState();
    const [authStatus, setAuthStatus] = useState();
    const [isRegister, setIsRegister] = useState(false);
    const appVerifier = window.recaptchaVerifier;
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const navigate = useNavigate();

    const initializeCaptcha = () => {
        // Initialize Firebase
        window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
            }
        }, auth);
    }
    const getVerificationCode = () => {
        signInWithPhoneNumber(auth, `${selectedCountry["dial_code"]}${phoneNumber}`, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                console.log(confirmationResult);
                setVerificationStatus(true);
                // ...
            }).catch((error) => {
                console.log(error);
                /* window.recaptchaVerifier.render().then(function (widgetId) {
                    grecaptcha.reset(widgetId);
                }); */
            });
    }
    const signIn = () => {
        window.confirmationResult.confirm(verificationCode).then((result) => {
            // User signed in successfully.
            const user = result.user;
            console.log(user)
            postAccountAuth();
            // ...
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
        });
    }
    useEffect(() => {
        initializeCaptcha();
    }, [])
    const [selectedCountry, setSelectedCountry] = useState();
    useEffect(() => {
        console.log(selectedCountry)
    }, [selectedCountry])
    const postAccountAuth = () => {
        const requestBody = {
            "accountName": name,
            "accountPhoneNumber": `${selectedCountry["dial_code"]}${phoneNumber}`,
            'accountCountry': selectedCountry["code"]
        }
        const requestMetadata = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        }
        fetch(`${apiUrl}/account/auth`, requestMetadata)
            .then(res => res.json())
            .then(
                (res) => {
                    if (res.responseStatus) {
                        localStorage.setItem("accountAuth", `${selectedCountry.dial_code}${phoneNumber}`)
                        localStorage.setItem("accountId", res.accountId)
                        navigate("/")
                    }
                    setAuthStatus(res.responseStatus);
                },
                (error) => {
                }
            )
    }
    return (
        <div className="auth sui-page pad fade-in">
            {/*           {!verificationStatus ?
                <Group
                    title={isRegister ? "Join to GoTransfer" : "Log In to GoTransfer"}
                    items=
                    {
                        [
                            isRegister ?
                                <GroupItem
                                    title="Name"
                                    trailing=
                                    {
                                        <InputField type="text" onChange={e => setName(e.target.value)} style={{ width: "100%" }} value={name} />
                                    }
                                    trailingStyle={{ width: "75%" }}
                                />
                                : null,
                            <GroupItem
                                title="Country"
                                trailing=
                                {
                                    <SelectBox items={countries} leader="emoji" trailing="dial_code" title="name" onChange={setSelectedCountry} initialValue={222} />
                                }
                            />,
                            <GroupItem
                                title="Phone Number"
                                trailing=
                                {
                                    <InputField type="text" onChange={e => setPhoneNumber(e.target.value)} style={{ width: "100%" }} value={phoneNumber} />
                                }
                                trailingStyle={{ width: "75%" }}
                            />
                        ]
                    }
                /> :
                <Group
                    title={`Verification for ${selectedCountry.dial_code}${phoneNumber}`}
                    items={
                        [

                            <GroupItem
                                title="Verification Code"
                                trailing=
                                {
                                    <InputField type="text" onChange={e => setVerificationCode(e.target.value)} style={{ width: "100%" }} maxLength={6} value={verificationCode} />
                                }
                                trailingStyle={{ width: "75%" }}
                            />,
                        ]
                    }
                />
            }
            <Group
                items=
                {
                    [
                        !verificationStatus ?
                            <GroupItem title={isRegister ? "Register" : "Log In"} titleStyle={{ color: "var(--link)" }}
                                onClick={() => getVerificationCode()}
                            />
                            :
                            <GroupItem title="Verify" titleStyle={{ color: "#007AFF" }}
                                onClick={() => signIn()}
                            />
                    ]
                }
            />
            {!verificationStatus ?
                <Group
                    items=
                    {
                        [
                            !isRegister ?
                                <GroupItem
                                    leader={<HiUserCircle />}
                                    title="Create an Account"
                                    additionalText="Register"
                                    trailing={<HiOutlineChevronRight fontSize={18} opacity={0.4} />}
                                    onClick={() => setIsRegister(true)}
                                />
                                :
                                <GroupItem title="Do You Have an Account?"
                                    additionalText="Log In"
                                    trailing={<HiOutlineChevronRight fontSize={18} opacity={0.4} />}
                                    onClick={() => setIsRegister(false)}
                                />
                        ]
                    }
                />
                : null}
            <div id="sign-in-button"></div> */}
        </div>
    )
}